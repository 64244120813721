<template>
    <div class="EditUser">
        <div class="information">
            <van-cell class="editHead" is-link center @click="picurlVisible = true">
                <template #title>
                    <div class="picture">
                        <img :src="getProfile" />
                    </div>
                    <p class="text">设置头像</p>
                </template>
            </van-cell>

            <!-- 商户用户 -->
            <van-cell class="editHead" is-link center v-if="userInfo.USER_TYPE === '1'" @click="picurlSellVisible = true">
                <template #title>
                    <div class="picture">
                        <img :src="getProfileSell" id="myImage" />
                    </div>
                    <input type="file" id="inputFile" style="display: none" @change="upload" />
                    <p class="text">设置商户头像</p>
                </template>
            </van-cell>

            <van-cell-group inset class="username">
                <van-field v-model="nickname" label="昵称" placeholder="请输入昵称" input-align="right" />
            </van-cell-group>
        </div>

        <!-- 预设头像反馈 -->
        <van-action-sheet v-model:show="picurlVisible" close-on-click-action @select="onCancel" title="选取头像">
            <div class="profiles">
                <ul @click="selectPresetProfile">
                    <li v-for="(src, index) in profiles" :key="index"><img :src="require('@/assets/profiles/' + src)" :data-src="src" /></li>
                </ul>
            </div>
        </van-action-sheet>

        <!-- 相册与照相反馈  -->
        <van-action-sheet v-model:show="picurlSellVisible" :actions="actions" cancel-text="取消" close-on-click-action @select="onCancel" />

        <van-button class="btn" type="primary" block round @click="ChangeUserInfo">确定</van-button>
    </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { changeUserInfo, Upload } from '@/api'
import { Toast } from 'vant'
import { GOPAY_USERINFO } from '@/assets/js/common.js'
import Compressor from 'compressorjs'
import http from '@/api/http.js'

export default {
    setup() {
        const state = reactive({
            fileConfig: '',
            nickname: '',

            // 会员头像
            picurl: '',
            newPicurl: '', // 更改操作
            profiles: ['image_1.png', 'image_2.png', 'image_3.png', 'image_4.png', 'image_5.png', 'image_6.png', 'image_7.png', 'image_8.png', 'image_9.png', 'image_10.png', 'image_11.png', 'image_12.png', 'image_13.png', 'image_14.png', 'image_15.png', 'image_16.png', 'image_17.png', 'image_18.png', 'image_19.png', 'image_20.png'],
            picurlVisible: false, // 预设头像弹窗

            // 商户头像
            picurlSell: '',
            newPicurlSell: '',
            picurlSellVisible: false // 相册与照相
        })

        const router = useRouter()
        const store = useStore()
        const userInfo = store.state.userInfo

        state.userInfo = userInfo
        state.picurl = userInfo.USER_PIC
        state.nickname = userInfo.NICK_NAME
        state.picurlSell = userInfo.USER_PIC_SELL // 商户头像

        state.fileConfig = store.state.fileConfig

        // 拍照
        const takePic = () => {
            navigator.camera.getPicture(onSuccess, onError, {
                quality: 50,
                destinationType: Camera.DestinationType.FILE_URI
            })
            function onSuccess(imageURI) {
                let arr = imageURI.split('/')
                let name = arr[arr.length - 1]

                http({
                    responseType: 'blob',
                    url: imageURI
                }).then(res => {
                    let file = new File([res], name, { type: res.type })
                    let fd = new FormData()
                    fd.append(name, file)

                    Upload(fd).then(res => {
                        if (res.status) {
                            state.newPicurlSell = res.data
                            // let image = document.getElementById('myImage')
                            // image.src = res.data

                            file.status = 'done'
                            file.message = '上传成功'
                            Toast.success('图片上传成功！')
                        } else {
                            state.picurl = ''

                            file.status = 'failed'
                            file.message = '上传失败'
                            Toast.fail(res.message)
                        }
                    })
                })
            }
            function onError(message) {
                console.log('拍照失败：' + message)
            }
        }

        // 预设头像选取动作
        const selectPresetProfile = e => {
            let target = e.target
            if (target.tagName === 'LI') target = target.querySelector('img')
            if (target.tagName === 'IMG') {
                let dataSrc = target.getAttribute('data-src')
                state.newPicurl = dataSrc
                state.picurlVisible = false
            }
        }

        // 从相册中选取
        const getPic = () => {
            let a = document.createEvent('MouseEvents')
            a.initEvent('click', true, true)
            document.getElementById('inputFile').dispatchEvent(a)
        }

        const upload = () => {
            let input = document.getElementById('inputFile')

            let files = input.files
            let file = files[0]

            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    let formData = new FormData()
                    formData.append('file', result, result.name)

                    Upload(formData).then(res => {
                        if (res.status) {
                            state.newPicurlSell = res.data

                            file.status = 'done'
                            file.message = '上传成功'

                            Toast.success('图片上传成功！')
                        } else {
                            state.picurl = ''

                            file.status = 'failed'
                            file.message = '上传失败'
                            Toast.fail(res.message)
                        }
                    })
                },
                error(err) {
                    console.log(err.message)
                }
            })
        }

        const actions = [
            { name: '拍照', callback: takePic },
            { name: '从相册中选取', callback: getPic }
        ]

        const onCancel = () => {
            state.show = false
        }

        // 修改个人信息
        const ChangeUserInfo = () => {
            console.log('======', state.newPicurlSell)
            changeUserInfo({
                nickname: state.nickname,
                picurl: state.newPicurl || null,
                picurl_sell: state.newPicurlSell || null
            }).then(res => {
                if (res.status) {
                    localStorage.removeItem(GOPAY_USERINFO)
                    store.dispatch('getUserInfo').then(() => {
                        Toast.success('修改成功！')
                        router.push('/User')
                    })
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        return {
            ...toRefs(state),
            router,
            onCancel,
            actions,
            selectPresetProfile,
            ChangeUserInfo,
            upload
        }
    },
    computed: {
        getProfile() {
            const profile = this.newPicurl || this.picurl || 'default.png'
            return require('@/assets/profiles/' + profile)
        },
        getProfileSell() {
            if (this.newPicurlSell) return this.fileConfig + this.newPicurlSell
            if (this.picurlSell) return this.fileConfig + this.picurlSell
            return require('@/assets/profiles/default.png')
        }
    }
}
</script>

<style lang="scss" scoped>
.profiles {
    height: 70vw;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 5vw;
    ul {
        display: flex;
        flex-flow: row wrap;
        text-align: center;
        li {
            flex: 0 0 30vw;
            height: 30vw;
            img {
                width: 20vw;
                height: 20vw;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }
}
.editHead ::v-deep .van-cell__title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.EditUser {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 w(10) h(50);

    .information {
        .picture {
            overflow: hidden;
            height: h(90);
            margin-right: w(10);
            border-radius: 50%;

            img {
                width: h(90);
                height: h(90);
            }
        }

        .text {
            font-size: 12px;
            line-height: 109.48%;
            color: #4f4f4f;
        }

        .username {
            margin-top: h(10);
        }
    }

    .btn {
        @include btn();
    }
}
</style>
